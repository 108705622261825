<div class="content">
  <p-card>
    <ng-template pTemplate="header">
      <div class="card-header p-card-title">
        {{ line?.name }}
        <span class="grade-font">{{ line?.grade.name | transloco }}</span>
      </div>
    </ng-template>

    <div class="flex gap-1">
      <lc-closed-spot-tag *ngIf="line?.closed"></lc-closed-spot-tag>
      <lc-secret-spot-tag *ngIf="line?.secret"></lc-secret-spot-tag>
    </div>

    <p-breadcrumb
      class="max-w-full"
      [model]="breadcrumbs"
      [home]="breadcrumbHome"
    ></p-breadcrumb>

    <p-tabMenu [model]="items" [scrollable]="true" *ngIf="line"></p-tabMenu>

    <router-outlet name="lineContent"></router-outlet>
  </p-card>
</div>
